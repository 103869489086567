import {
   ApolloClient,
   ApolloProvider,
   createHttpLink,
   DefaultOptions,
   InMemoryCache,
} from '@apollo/client';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import RoutesComponent from './features/Routing/components/Routes/Routes';
import theme from './shared/utilities/theme';
import { setContext } from '@apollo/client/link/context';
import { getCookieValue } from './shared/utilities/helpers';

const httpLink = createHttpLink({
   uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
   // get the authentication token from local storage if it exists
   const token = getCookieValue('CF_Authorization');
   console.log('token', token);
   // return the headers to the context so httpLink can read them
   return {
      headers: {
         ...headers,
         authorization: token ? `Bearer ${token}` : '',
      },
   };
});
const defaultOptions: DefaultOptions = {
   watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
   },
   query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
   },
};

const client = new ApolloClient({
   cache: new InMemoryCache(),
   link: authLink.concat(httpLink),
   defaultOptions,
});

function App() {
   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <ApolloProvider client={client}>
            <Box height='100vh' display='flex' flexDirection='column'>
               <RoutesComponent />
            </Box>
         </ApolloProvider>
      </ThemeProvider>
   );
}

export default App;
