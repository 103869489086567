import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type ActiveInviteProfileModel = {
  __typename?: 'ActiveInviteProfileModel';
  commonTeammates: Array<DatingProfileModel>;
  datingProfile: DatingProfileModel;
  type: Scalars['String'];
  username: Scalars['String'];
};

export type AdminModel = {
  __typename?: 'AdminModel';
  id: Scalars['ID'];
  username: Scalars['String'];
};

export type AgeDistributionModel = {
  __typename?: 'AgeDistributionModel';
  age: Scalars['String'];
  count: Scalars['Float'];
};

export type AuditlogModel = {
  __typename?: 'AuditlogModel';
  callerId: Scalars['String'];
  callerName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  exception: Scalars['Boolean'];
  id: Scalars['ID'];
  input: Scalars['JSON'];
  operation: Scalars['String'];
  operationType: Scalars['String'];
  output: Scalars['JSON'];
  resourceType: Scalars['String'];
};

export type AuthTokensModel = {
  __typename?: 'AuthTokensModel';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type AutoCompleteAddressInput = {
  city: Scalars['String'];
  countryCodeISO3: Scalars['String'];
  formatted: InputMaybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  neighborhood: InputMaybe<Scalars['String']>;
  postalCode: InputMaybe<Scalars['String']>;
  streetName: InputMaybe<Scalars['String']>;
  streetNumber: InputMaybe<Scalars['String']>;
};

export type AutoCompleteAddressModel = {
  __typename?: 'AutoCompleteAddressModel';
  city: Scalars['String'];
  countryCodeISO3: Scalars['String'];
  formatted: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  neighborhood: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  streetName: Maybe<Scalars['String']>;
  streetNumber: Maybe<Scalars['String']>;
};

export type ChatModel = {
  __typename?: 'ChatModel';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isSeen: Scalars['Boolean'];
  latestMessage: Maybe<MessageModel>;
  matchUsers: Array<ChatUserModel>;
  type: Maybe<Scalars['String']>;
  typeId: Scalars['String'];
  users: Array<ChatUserModel>;
};

export type ChatUserModel = {
  __typename?: 'ChatUserModel';
  atRoskildeFestival: Scalars['Boolean'];
  avatar: Scalars['String'];
  datingProfileId: Scalars['ID'];
  displayName: Scalars['String'];
  lastSeen: Maybe<Scalars['DateTime']>;
  relationship: Scalars['String'];
};

export type CreatePlaceInput = {
  address: AutoCompleteAddressInput;
  base64Image: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: InputMaybe<Scalars['String']>;
};

export type DatingProfileInfoInput = {
  algorithm: InputMaybe<Scalars['Float']>;
  bio: InputMaybe<Scalars['String']>;
  birthDate: InputMaybe<Scalars['DateTime']>;
  city: InputMaybe<AutoCompleteAddressInput>;
  email: InputMaybe<Scalars['String']>;
  gender: InputMaybe<Gender>;
  language: InputMaybe<Locales>;
  maxSuggestionAge: InputMaybe<Scalars['Int']>;
  maxSuggestionDistance: InputMaybe<Scalars['Float']>;
  minSuggestionAge: InputMaybe<Scalars['Int']>;
  name: InputMaybe<Scalars['String']>;
  occupation: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  school: InputMaybe<Scalars['String']>;
  sexualOrientations: InputMaybe<Array<Gender>>;
  username: InputMaybe<Scalars['String']>;
};

export type DatingProfileModel = {
  __typename?: 'DatingProfileModel';
  acceptsShowingEventParticipation: Scalars['Boolean'];
  atRoskildeFestival: Scalars['Boolean'];
  bio: Scalars['String'];
  birthDate: Maybe<Scalars['DateTime']>;
  cell: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  gender: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interests: Array<InterestModel>;
  language: Scalars['String'];
  matchingActive: Maybe<Scalars['Boolean']>;
  maxSuggestionAge: Maybe<Scalars['Int']>;
  maxSuggestionDistance: Maybe<Scalars['Float']>;
  minSuggestionAge: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfTeammates: Maybe<Scalars['Float']>;
  occupation: Scalars['String'];
  phoneNumber: Scalars['String'];
  potentialDoubbleMatches: Array<DatingProfileModel>;
  profileImages: Array<ImageAndThumbnailModel>;
  school: Scalars['String'];
  sexualOrientations: Maybe<Array<Scalars['String']>>;
  suspension: Maybe<SuspensionModel>;
  userId: Scalars['ID'];
  userRegion: Region;
  username: Scalars['String'];
};

export type DeleteDatingProfileInput = {
  datingProfileId: Scalars['String'];
};

export type DoubbleMatchChatModel = {
  __typename?: 'DoubbleMatchChatModel';
  id: Scalars['ID'];
  match: ChatUserModel;
  teammate: ChatUserModel;
  teammateMatch: ChatUserModel;
  you: ChatUserModel;
};

export type DoubbleMatchDistributionModel = {
  __typename?: 'DoubbleMatchDistributionModel';
  count: Scalars['Float'];
  doubbleMatches: Scalars['Float'];
};

export type DoubbleMatchModel = {
  __typename?: 'DoubbleMatchModel';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lastSeenMessage: Maybe<Scalars['DateTime']>;
  lastSeenTeamMessage: Maybe<Scalars['DateTime']>;
  latestMessage: Maybe<MessageModel>;
  latestTeamMessage: Maybe<MessageModel>;
  teammateMatchWithDistance: Array<RelevantDatingProfileModel>;
  yourMatchWithDistance: Array<RelevantDatingProfileModel>;
};

export type EditPlaceInput = {
  address: InputMaybe<AutoCompleteAddressInput>;
  base64Image: InputMaybe<Scalars['String']>;
  category: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: InputMaybe<Scalars['String']>;
  phoneNumber: InputMaybe<Scalars['String']>;
};

export type GenderDistributionModel = {
  __typename?: 'GenderDistributionModel';
  count: Scalars['Float'];
  gender: Scalars['String'];
};

export type ImageAndThumbnailModel = {
  __typename?: 'ImageAndThumbnailModel';
  imageUrl: Scalars['String'];
  thumbnailUrl: Scalars['String'];
};

export type InterestIdListInput = {
  ids: InputMaybe<Array<Scalars['String']>>;
};

export type InterestModel = {
  __typename?: 'InterestModel';
  category: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type LikesAndDislikesModel = {
  __typename?: 'LikesAndDislikesModel';
  dislikes: Scalars['Float'];
  likeToDislikeRatio: Scalars['Float'];
  likes: Scalars['Float'];
};

export type LogoutInput = {
  refreshToken: Scalars['String'];
};

export type MessageModel = {
  __typename?: 'MessageModel';
  chat: ChatModel;
  content: Scalars['String'];
  id: Scalars['ID'];
  sender: ChatUserModel;
  senderId: Maybe<Scalars['String']>;
  time: Scalars['DateTime'];
  type: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   *  - UPLOAD_FAILED
   */
  addImageToDatingProfile: ImageAndThumbnailModel;
  addNotificationToken: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - DATING_PROFILE_NOT_FOUND
   */
  adminDeleteAccount: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - NOTIFICATION_TOKEN_NOT_FOUND
   *  - PARTIAL_TOKEN_OPTION_ERROR
   */
  changeNotificationsOptions: Array<NotificationOptionModel>;
  /**
   * Errors:
   *  -
   *  - DUPLICATE_EMAIL_ERROR
   *  - DUPLICATE_USERNAME_ERROR
   */
  createDatingProfile: DatingProfileModel;
  /**
   * Errors:
   *  -
   *  - UPLOAD_FAILED
   */
  createPlace: PlaceModel;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   */
  deleteAccount: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - PLACE_NOT_FOUND
   */
  deletePlace: PlaceModel;
  /**
   * Errors:
   *  -
   *  - NOTIFICATION_TOKEN_NOT_FOUND
   */
  disableNotificationToken: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - DATING_PROFILE_NOT_FOUND
   *  - HAS_NO_DATING_PROFILE
   */
  dislike: SwipeModel;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  editDatingProfile: DatingProfileModel;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   *  - HAS_NO_DATING_PROFILE
   */
  editInterests: Array<InterestModel>;
  /**
   * Errors:
   *  -
   *  - PLACE_NOT_FOUND
   *  - UPLOAD_FAILED
   */
  editPlace: PlaceModel;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   */
  editUser: UserModel;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  ignoreTeammateSuggestion: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  inviteProfile: ActiveInviteProfileModel;
  /**
   * Errors:
   *  -
   *  - DATING_PROFILE_NOT_FOUND
   *  - HAS_NO_DATING_PROFILE
   */
  like: SwipeModel;
  /**
   * Errors:
   *  -
   *  - INVALID_TOKEN
   *  - RATE_LIMIT
   */
  login: AuthTokensModel;
  logout: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - CHAT_NOT_FOUND
   *  - MESSAGE_NOT_FOUND
   *  - UNAUTHORIZED_ERROR
   */
  messageSeen: Scalars['DateTime'];
  /**
   * Errors:
   *  -
   *  - CHAT_NOT_FOUND
   *  - UNAUTHORIZED_ERROR
   */
  recommendPlace: MessageModel;
  /**
   * Errors:
   *  -
   *  - INVALID_REFRESH_TOKEN_ERROR
   *  - REFRESH_TOKEN_EXPIRED_ERROR
   */
  refreshAuthTokens: AuthTokensModel;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   *  - HAS_NO_DATING_PROFILE
   *  - IMAGE_NOT_FOUND
   */
  removeImageFromDatingProfile: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  removeTeammate: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  removeTeammateV2: Scalars['Boolean'];
  reportContent: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - PHONE_NUMBER_NOT_FOUND
   *  - SMS_NOT_SENT
   */
  requestLogin: Scalars['String'];
  /**
   * Errors:
   *  -
   *  - PHONE_ALREADY_IN_USE
   *  - SMS_NOT_SENT
   */
  requestUserCreation: Scalars['String'];
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   *  - INVITE_NOT_FOUND
   */
  respondToInvite: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   *  - INVITE_NOT_FOUND
   */
  respondToInviteV2: Scalars['Boolean'];
  revokeSuspension: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  saveProfileContacts: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - CHAT_NOT_FOUND
   *  - UNAUTHORIZED_ERROR
   */
  sendMessage: MessageModel;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   */
  setLocation: Array<Scalars['Float']>;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   */
  setMatchingStatus: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - USER_NOT_FOUND
   */
  setSuspension: SuspensionModel;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  suggestPlace: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - LIKE_DISLIKE_NOT_FOUND
   *  - HAS_NO_DATING_PROFILE
   */
  undoLikeOrDislike: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - DOUBBLE_MATCH_NOT_FOUND
   *  - HAS_NO_DATING_PROFILE
   */
  unmatchDoubbleMatch: Scalars['Boolean'];
  /**
   * Errors:
   *  -
   *  - INVALID_TOKEN
   *  - RATE_LIMIT
   */
  verifyAndCreateUser: AuthTokensModel;
};


export type MutationAddImageToDatingProfileArgs = {
  base64File: Scalars['String'];
};


export type MutationAddNotificationTokenArgs = {
  token: Scalars['String'];
};


export type MutationAdminDeleteAccountArgs = {
  deleteInput: DeleteDatingProfileInput;
};


export type MutationChangeNotificationsOptionsArgs = {
  notificationOptions: Array<NotificationOptionInput>;
  token: Scalars['String'];
};


export type MutationCreateDatingProfileArgs = {
  email: Scalars['String'];
  info: DatingProfileInfoInput;
  username: Scalars['String'];
};


export type MutationCreatePlaceArgs = {
  createPlaceInput: CreatePlaceInput;
};


export type MutationDeletePlaceArgs = {
  placeId: Scalars['String'];
};


export type MutationDisableNotificationTokenArgs = {
  token: Scalars['String'];
};


export type MutationDislikeArgs = {
  category: InputMaybe<Scalars['Float']>;
  datingProfileId: Scalars['String'];
};


export type MutationEditDatingProfileArgs = {
  info: DatingProfileInfoInput;
};


export type MutationEditInterestsArgs = {
  add?: InputMaybe<InterestIdListInput>;
  remove?: InputMaybe<InterestIdListInput>;
};


export type MutationEditPlaceArgs = {
  input: EditPlaceInput;
};


export type MutationEditUserArgs = {
  email: InputMaybe<Scalars['String']>;
  language: InputMaybe<Locales>;
  phone: InputMaybe<Scalars['String']>;
  username: InputMaybe<Scalars['String']>;
};


export type MutationIgnoreTeammateSuggestionArgs = {
  profileToIgnore: Scalars['String'];
};


export type MutationInviteProfileArgs = {
  username: Scalars['String'];
};


export type MutationLikeArgs = {
  category: InputMaybe<Scalars['Float']>;
  datingProfileId: Scalars['String'];
};


export type MutationLoginArgs = {
  token: Scalars['String'];
  userIdentifier: Scalars['String'];
};


export type MutationLogoutArgs = {
  logoutInput: LogoutInput;
};


export type MutationMessageSeenArgs = {
  messageId: Scalars['String'];
};


export type MutationRecommendPlaceArgs = {
  chatId: Scalars['String'];
  placeId: Scalars['String'];
};


export type MutationRefreshAuthTokensArgs = {
  refreshAuthTokensInput: RefreshAuthTokensInput;
};


export type MutationRemoveImageFromDatingProfileArgs = {
  imageUrl: Scalars['String'];
};


export type MutationRemoveTeammateArgs = {
  teammateId: Scalars['String'];
};


export type MutationRemoveTeammateV2Args = {
  teammateId: Scalars['String'];
};


export type MutationReportContentArgs = {
  report: ReportInput;
};


export type MutationRequestLoginArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationRequestUserCreationArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationRespondToInviteArgs = {
  inviterId: Scalars['String'];
  response: InviteResponse;
};


export type MutationRespondToInviteV2Args = {
  inviterId: Scalars['String'];
  response: InviteResponse;
};


export type MutationRevokeSuspensionArgs = {
  revokeInput: RevokeSuspensionInput;
};


export type MutationSaveProfileContactsArgs = {
  contactInformationInputs?: InputMaybe<Array<ProfileContactInformationInput>>;
};


export type MutationSendMessageArgs = {
  chatId: Scalars['String'];
  message: Scalars['String'];
};


export type MutationSetLocationArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


export type MutationSetMatchingStatusArgs = {
  status: Scalars['Boolean'];
};


export type MutationSetSuspensionArgs = {
  suspensionInput: SuspensionInput;
};


export type MutationSuggestPlaceArgs = {
  suggestion: PlaceSuggestionInput;
};


export type MutationUndoLikeOrDislikeArgs = {
  swipedId: Scalars['String'];
};


export type MutationUnmatchDoubbleMatchArgs = {
  doubbleMatchId: Scalars['String'];
};


export type MutationVerifyAndCreateUserArgs = {
  token: Scalars['String'];
  userIdentifier: Scalars['String'];
};

export type NotificationOptionInput = {
  allowed: Scalars['Boolean'];
  type: Scalars['String'];
};

export type NotificationOptionModel = {
  __typename?: 'NotificationOptionModel';
  allowed: Scalars['Boolean'];
  type: Scalars['String'];
};

export type PaginatedAuditlogModelResponse = {
  __typename?: 'PaginatedAuditlogModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<AuditlogModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedChatModelResponse = {
  __typename?: 'PaginatedChatModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<ChatModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedDatingProfileModelResponse = {
  __typename?: 'PaginatedDatingProfileModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<DatingProfileModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedMessageModelResponse = {
  __typename?: 'PaginatedMessageModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<MessageModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedPlaceModelResponse = {
  __typename?: 'PaginatedPlaceModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<PlaceModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedRelevantDatingProfileModelResponse = {
  __typename?: 'PaginatedRelevantDatingProfileModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<RelevantDatingProfileModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedReportModelResponse = {
  __typename?: 'PaginatedReportModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<ReportModel>;
  total: Maybe<Scalars['Int']>;
};

export type PlaceDistanceModel = {
  __typename?: 'PlaceDistanceModel';
  distance: Scalars['Float'];
  place: PlaceModel;
};

export type PlaceModel = {
  __typename?: 'PlaceModel';
  address: AutoCompleteAddressModel;
  category: Scalars['String'];
  id: Scalars['ID'];
  image: Maybe<ImageAndThumbnailModel>;
  name: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  statistics: Maybe<PlaceStatisticModel>;
};

export type PlaceStatisticModel = {
  __typename?: 'PlaceStatisticModel';
  recommendedCount: Maybe<Scalars['Float']>;
};

export type PlaceSuggestionInput = {
  text: Scalars['String'];
};

export type ProfileContactInformationInput = {
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  ageDistribution: Array<AgeDistributionModel>;
  auditlogs: PaginatedAuditlogModelResponse;
  averageTeammatesPrUser: Scalars['Float'];
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   *  - CHAT_NOT_FOUND
   */
  chatForDoubbleMatch: DoubbleMatchChatModel;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   */
  chats: PaginatedChatModelResponse;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   *  - HAS_NO_DATING_PROFILE
   */
  currentDatingProfile: DatingProfileModel;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   *  - DATING_PROFILE_NOT_FOUND
   */
  datingProfileByUsername: DatingProfileModel;
  /**
   * Errors:
   *  -
   *  - DATING_PROFILE_NOT_FOUND
   */
  datingprofile: DatingProfileModel;
  datingprofiles: PaginatedDatingProfileModelResponse;
  /**
   * Errors:
   *  -
   *  - DOUBBLE_MATCH_NOT_FOUND
   *  - UNAUTHORIZED_ERROR
   *  - HAS_NO_DATING_PROFILE
   */
  doubbleMatchById: DoubbleMatchModel;
  doubbleMatchDistribution: Array<DoubbleMatchDistributionModel>;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   *  - HAS_NO_DATING_PROFILE
   */
  doubbleMatches: Array<DoubbleMatchModel>;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  doubbleMatchesCount: Scalars['Float'];
  emailInUse: Scalars['Boolean'];
  genderDistribution: Array<GenderDistributionModel>;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  geocode: Array<AutoCompleteAddressModel>;
  interests: Array<InterestModel>;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  invitedProfiles: Array<ActiveInviteProfileModel>;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  invitingProfiles: Array<ActiveInviteProfileModel>;
  likesAndDislikesCount: LikesAndDislikesModel;
  matchesNotPartOfDoubbleMatchCount: Scalars['Float'];
  /**
   * Errors:
   *  -
   *  - MESSAGE_NOT_FOUND
   *  - UNAUTHORIZED_ERROR
   */
  messageById: MessageModel;
  /**
   * Errors:
   *  -
   *  - CHAT_NOT_FOUND
   *  - UNAUTHORIZED_ERROR
   */
  messages: PaginatedMessageModelResponse;
  /**
   * Errors:
   *  -
   *  - NOTIFICATION_TOKEN_NOT_FOUND
   */
  notificationOptions: Array<NotificationOptionModel>;
  oneSidedLikes: Scalars['Float'];
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  paginatedRelevantDatingProfiles: PaginatedRelevantDatingProfileModelResponse;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  places: Array<PlaceDistanceModel>;
  placesWithoutDistance: PaginatedPlaceModelResponse;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  profileContacts: UserContactsModel;
  reports: PaginatedReportModelResponse;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  suggestedTeammates: Array<SuggestedTeammateModel>;
  /**
   * Errors:
   *  -
   *  - CHAT_NOT_FOUND
   *  - UNAUTHORIZED_ERROR
   */
  teamChatForDoubbleMatch: TeamChatModel;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   * @deprecated Deprecated in favor of active-invite-profiles. TODO: remove after 2022-07-01
   */
  teammateInvites: Array<TeamMateInviteModel>;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  teammates: Array<TeammateModel>;
  teammatesDistribution: Array<TeammatesDistributionModel>;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  teammatesV2: Array<TeammateModel>;
  totalDatingProfileCount: Scalars['Float'];
  totalDoubbleMatchesCount: Scalars['Float'];
  totalMatchesCount: Scalars['Float'];
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   *  - DATING_PROFILE_NOT_FOUND
   */
  trueUsers: Array<TrueUserModel>;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  unknownRelationProfile: UnknownRelationProfileModel;
  /**
   * Errors:
   *  -
   *  - HAS_NO_DATING_PROFILE
   */
  unreadChats: Scalars['Float'];
  usernameInUse: Scalars['Boolean'];
  usernameSearch: Array<UsernameSearchModel>;
  /**
   * Errors:
   *  -
   *  - UNAUTHORIZED_ERROR
   */
  whoAdminI: AdminModel;
};


export type QueryAuditlogsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryChatForDoubbleMatchArgs = {
  doubbleMatchId: Scalars['String'];
};


export type QueryChatsArgs = {
  cursor: InputMaybe<Scalars['DateTime']>;
  query?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  withoutMessages?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDatingProfileByUsernameArgs = {
  username: Scalars['String'];
};


export type QueryDatingprofileArgs = {
  datingProfileId: Scalars['String'];
};


export type QueryDatingprofilesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryDoubbleMatchByIdArgs = {
  doubbleMatchId: Scalars['String'];
};


export type QueryEmailInUseArgs = {
  email: Scalars['String'];
};


export type QueryGeocodeArgs = {
  onlyCities?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryMessageByIdArgs = {
  messageId: Scalars['String'];
};


export type QueryMessagesArgs = {
  chatId: Scalars['String'];
  cursor: InputMaybe<Scalars['DateTime']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryNotificationOptionsArgs = {
  token: Scalars['String'];
};


export type QueryPaginatedRelevantDatingProfilesArgs = {
  excludeIds?: InputMaybe<Array<Scalars['String']>>;
  skip?: InputMaybe<Scalars['Int']>;
  swipeCount?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPlacesArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  maxDistance?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPlacesWithoutDistanceArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryReportsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryTeamChatForDoubbleMatchArgs = {
  doubbleMatchId: Scalars['String'];
};


export type QueryTrueUsersArgs = {
  usernames?: InputMaybe<Scalars['String']>;
};


export type QueryUnknownRelationProfileArgs = {
  username: Scalars['String'];
};


export type QueryUsernameInUseArgs = {
  username: Scalars['String'];
};


export type QueryUsernameSearchArgs = {
  searchString: Scalars['String'];
};

export type RefreshAuthTokensInput = {
  refreshToken: Scalars['String'];
};

export type RelevantDatingProfileModel = {
  __typename?: 'RelevantDatingProfileModel';
  category: Maybe<Scalars['Float']>;
  datingProfile: DatingProfileModel;
  distance: Scalars['Float'];
  numberOfTeammates: Maybe<Scalars['Float']>;
  potentialDoubbleMatchWith: Maybe<TeammateModel>;
  username: Scalars['String'];
};

export type ReportInput = {
  message: InputMaybe<Scalars['String']>;
  offendingElementId: Scalars['String'];
  type: ReportType;
};

export type ReportModel = {
  __typename?: 'ReportModel';
  content: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  creatorOfReportedContentId: Scalars['String'];
  id: Scalars['ID'];
  message: Maybe<Scalars['String']>;
  reportedElementId: Scalars['String'];
  reportingUserId: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ReportType {
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  DATING_PROFILE = 'DATING_PROFILE'
}

export type RevokeSuspensionInput = {
  suspensionId: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /**
   * Errors:
   *  -
   *  - CHAT_NOT_FOUND
   *  - UNAUTHORIZED_ERROR
   */
  subscribeToNewMessages: MessageModel;
};


export type SubscriptionSubscribeToNewMessagesArgs = {
  chatId: Scalars['String'];
};

export type SuggestedTeammateModel = {
  __typename?: 'SuggestedTeammateModel';
  commonTeammates: Array<DatingProfileModel>;
  datingProfile: DatingProfileModel;
  inContacts: Scalars['Boolean'];
  potentialMatches: Scalars['Float'];
  type: Scalars['String'];
  username: Scalars['String'];
};

export type SuspensionInput = {
  permanent: Scalars['Boolean'];
  until: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SuspensionModel = {
  __typename?: 'SuspensionModel';
  id: Scalars['ID'];
  permanent: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  until: Maybe<Scalars['DateTime']>;
};

export type SwipeModel = {
  __typename?: 'SwipeModel';
  activeAfter: Scalars['DateTime'];
  id: Scalars['String'];
  likedId: Scalars['String'];
  likerId: Scalars['String'];
  swipedId: Scalars['String'];
  swiperId: Scalars['String'];
  swiperLike: Scalars['Boolean'];
  verdict: Verdict;
};

export type TeamChatModel = {
  __typename?: 'TeamChatModel';
  id: Scalars['ID'];
  teammate: ChatUserModel;
  you: ChatUserModel;
};

export type TeamMateInviteModel = {
  __typename?: 'TeamMateInviteModel';
  invited: DatingProfileModel;
  inviter: DatingProfileModel;
};

export type TeammateModel = {
  __typename?: 'TeammateModel';
  commonTeammates: Array<DatingProfileModel>;
  datingProfile: DatingProfileModel;
  numberOfMatches: Scalars['Float'];
  type: Scalars['String'];
  username: Scalars['String'];
};

export type TeammatesDistributionModel = {
  __typename?: 'TeammatesDistributionModel';
  count: Scalars['Float'];
  teammates: Scalars['Float'];
};

export type TrueUserModel = {
  __typename?: 'TrueUserModel';
  averageMessageSentPerChat: Scalars['Float'];
  chatInteractions: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  doubbleMatchesCount: Scalars['Float'];
  experiencesSuggested: Scalars['Float'];
  friendsCount: Scalars['Float'];
  hasBio: Scalars['Boolean'];
  hasCity: Scalars['Boolean'];
  hasOccupation: Scalars['Boolean'];
  hasSchool: Scalars['Boolean'];
  id: Scalars['ID'];
  interestsCount: Scalars['Float'];
  lastActive: Maybe<Scalars['DateTime']>;
  matchesCount: Scalars['Float'];
  matchingActive: Scalars['Boolean'];
  profileImagesCount: Scalars['Float'];
  sentMessages: Scalars['Float'];
  swipesCount: Scalars['Float'];
  trueUser: Scalars['Boolean'];
  username: Scalars['String'];
};

export type UnknownRelationProfileModel = {
  __typename?: 'UnknownRelationProfileModel';
  commonTeammates: Array<DatingProfileModel>;
  datingProfile: DatingProfileModel;
  type: Scalars['String'];
  username: Scalars['String'];
};

export type UserContactInformationModel = {
  __typename?: 'UserContactInformationModel';
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId: Maybe<Scalars['String']>;
};

export type UserContactsModel = {
  __typename?: 'UserContactsModel';
  contacts: Array<UserContactInformationModel>;
  datingProfileId: Scalars['ID'];
};

export type UserModel = {
  __typename?: 'UserModel';
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  username: Maybe<Scalars['String']>;
};

export type UsernameSearchModel = {
  __typename?: 'UsernameSearchModel';
  name: Scalars['String'];
  thumbnailUrl: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export enum Verdict {
  Dislike = 'Dislike',
  Like = 'Like'
}

export enum Gender {
  FEMALE = 'FEMALE',
  Female = 'Female',
  MALE = 'MALE',
  Male = 'Male',
  NON_BINARY = 'NON_BINARY',
  NonBinary = 'NonBinary'
}

export enum InviteResponse {
  Accept = 'Accept',
  Reject = 'Reject'
}

export enum Locales {
  DA = 'DA',
  EN = 'EN',
  SV = 'SV',
  da = 'da',
  en = 'en',
  sv = 'sv'
}

export enum Region {
  EUROPE_WEST = 'EUROPE_WEST',
  US_EAST = 'US_EAST',
  US_WEST = 'US_WEST'
}

export type RequestTrueUsersQueryVariables = Exact<{
  usernames: Scalars['String'];
}>;


export type RequestTrueUsersQuery = { __typename?: 'Query', trueUsers: Array<{ __typename?: 'TrueUserModel', id: string, username: string, trueUser: boolean, hasBio: boolean, hasCity: boolean, hasOccupation: boolean, hasSchool: boolean, averageMessageSentPerChat: number, chatInteractions: number, createdAt: any, lastActive: any | null, swipesCount: number, doubbleMatchesCount: number, interestsCount: number, matchesCount: number, experiencesSuggested: number, sentMessages: number, profileImagesCount: number, friendsCount: number, matchingActive: boolean }> };


export const RequestTrueUsersDocument = gql`
    query RequestTrueUsers($usernames: String!) {
  trueUsers(usernames: $usernames) {
    id
    username
    trueUser
    hasBio
    hasCity
    hasOccupation
    hasSchool
    averageMessageSentPerChat
    chatInteractions
    createdAt
    lastActive
    swipesCount
    doubbleMatchesCount
    interestsCount
    matchesCount
    experiencesSuggested
    sentMessages
    profileImagesCount
    friendsCount
    matchingActive
  }
}
    `;

/**
 * __useRequestTrueUsersQuery__
 *
 * To run a query within a React component, call `useRequestTrueUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestTrueUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestTrueUsersQuery({
 *   variables: {
 *      usernames: // value for 'usernames'
 *   },
 * });
 */
export function useRequestTrueUsersQuery(baseOptions: Apollo.QueryHookOptions<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>(RequestTrueUsersDocument, options);
      }
export function useRequestTrueUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>(RequestTrueUsersDocument, options);
        }
export type RequestTrueUsersQueryHookResult = ReturnType<typeof useRequestTrueUsersQuery>;
export type RequestTrueUsersLazyQueryHookResult = ReturnType<typeof useRequestTrueUsersLazyQuery>;
export type RequestTrueUsersQueryResult = Apollo.QueryResult<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>;
export function refetchRequestTrueUsersQuery(variables: RequestTrueUsersQueryVariables) {
      return { query: RequestTrueUsersDocument, variables: variables }
    }