// other
import { FC } from 'react';
import Home from '../../../screens/Home';

// interface
interface Route {
   key: string;
   title: string;
   path: string;
   enabled: boolean;
   component: FC<any>;
}

export const routes: Array<Route> = [
   {
      key: 'home-route',
      title: 'Home',
      path: '/',
      enabled: true,
      component: Home,
   },
];
