import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
   DataGrid,
   GridColDef,
   GridSortModel,
   GridToolbarContainer,
   GridToolbarExport,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { RequestTrueUsersQuery } from '../../../../shared/utilities/__generated__/graphql';

const columns: GridColDef[] = [
   { field: 'username', headerName: 'Username' },
   {
      field: 'trueUser',
      headerName: 'True user',
      type: 'boolean',
   },
   { field: 'friendsCount', headerName: 'Friends', type: 'number' },
   { field: 'swipesCount', headerName: 'Swipes', type: 'number' },
   { field: 'doubbleMatchesCount', headerName: 'Doubble Matches', type: 'number', width: 140 },
   { field: 'matchesCount', headerName: 'Matches', type: 'number' },
   { field: 'averageMessageSentPerChat', headerName: 'Avg. messages per chat', type: 'number' },
   { field: 'sentMessages', headerName: 'Sent messages', type: 'number' },
   { field: 'experiencesSuggested', headerName: 'Suggested experiences', type: 'number' },
   {
      field: 'matchingActive',
      headerName: 'Active',
      type: 'boolean',
   },
   {
      field: 'hasBio',
      headerName: 'Has bio',
      type: 'boolean',
   },
   {
      field: 'hasOccupation',
      headerName: 'Has occupation',
      type: 'boolean',
   },
   {
      field: 'hasCity',
      headerName: 'Has city',
      type: 'boolean',
   },
   {
      field: 'hasSchool',
      headerName: 'Has school',
      type: 'boolean',
   },
   {
      field: 'createdAt',
      headerName: 'Created at',
      type: 'dateTime',
      width: 200,
      valueGetter: ({ value }) => value && new Date(value),
   },
   {
      field: 'lastActive',
      headerName: 'Last active',
      type: 'dateTime',
      width: 200,
      valueGetter: ({ value }) => value && new Date(value),
   },
];
function CustomToolbar() {
   return (
      <GridToolbarContainer>
         <GridToolbarExport />
      </GridToolbarContainer>
   );
}

const UserTable = (props: { data: RequestTrueUsersQuery; usernames: string[] }) => {
   const [sortModel, setSortModel] = useState<GridSortModel>([
      {
         field: 'trueUser',
         sort: 'desc',
      },
   ]);

   const usersNotFound = props.usernames
      .filter(
         (inputtedUsername) =>
            !props.data.trueUsers.some(({ username }) => inputtedUsername === username),
      )
      .map((username) => ({ username, id: username }));

   const rows = [...props.data.trueUsers, ...usersNotFound];

   const trueUsers = props.data.trueUsers.filter(({ trueUser }) => trueUser);

   return (
      <>
         <Box sx={{ mb: 5 }}>
            <Table sx={{ width: 'auto', margin: 'auto' }} size='small' aria-label='a dense table'>
               <TableHead>
                  <TableRow>
                     <TableCell colSpan={2}>Summary</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  <TableRow>
                     <TableCell>True users:</TableCell>
                     <TableCell>{trueUsers.length}</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                     <TableCell component='th' scope='row'>
                        Users not found:
                     </TableCell>
                     <TableCell>{usersNotFound.length}</TableCell>
                  </TableRow>
               </TableBody>
            </Table>
         </Box>
         <div style={{ height: 700, width: '100%' }}>
            <DataGrid
               rows={rows}
               columns={columns}
               pageSize={30}
               rowsPerPageOptions={[5]}
               checkboxSelection
               sortModel={sortModel}
               onSortModelChange={(model) => setSortModel(model)}
               components={{
                  Toolbar: CustomToolbar,
               }}
            />
         </div>
      </>
   );
};

export default UserTable;
