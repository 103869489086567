import { createTheme } from '@mui/material/styles';

export default createTheme({
   palette: {
      primary: {
         light: '#FFE0FF',
         main: '#FFBAFF',
         dark: '#FCABFF',
         contrastText: '#000',
      },
      secondary: {
         main: '#7654AA',
         light: '#7654AA',
         dark: '#2b134f',
         contrastText: '#FFF',
      },
   },
});
